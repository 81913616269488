import React from 'react';
import Style from '../styles/map.module.scss'
import SectionStyle from '../styles/section.module.scss'
import Point from '../images/point.svg'
import { FaPhoneAlt } from "react-icons/fa";
import { YMaps, Map,Placemark } from '@pbe/react-yandex-maps';

const UiMap = ({
  second
}) => {
  return (
    <section className={[SectionStyle.section,(second)? SectionStyle.secondary : SectionStyle.primary].join(" ")}>
      <YMaps>
      <div className={SectionStyle.wrapper}>
        <h1 className={SectionStyle.title}>Мы на картах</h1>
        <div className={Style.content}>
          <div className={Style.map}>
            <Map 
              defaultState={{ center: [54.640503, 56.162725], zoom: 13 }} 
              width={"100%"}
              height={"100%"}
            >
              <Placemark geometry={[54.640503, 56.162725]} />
            </Map>
          </div>
          <div className={Style.points}>
            <div className={Style.point}>
              <div className={Style.info}>
                <address className={Style.adress}>д. Бурцево Уфимского района</address>
              </div>
            </div>
          </div>
        </div>
      </div>
      </YMaps>
    </section>
  );
};

export default UiMap; 
